/* @import '~antd/dist/antd.css'; */
@font-face {
  font-family: 'AweleText';
  src: local('AweleText'), url('AweleText-Regular.woff2') format('truetype');
}

@font-face {
  font-family: 'AweleHeadlineText';
  src: local('AweleHeadlineText'), url('AweleHeadline-Regular.woff2') format('truetype');
}

@font-face {
  font-family: 'AweleHeadlineText-Light';
  src: local('AweleHeadlineText-Light'), url('AweleHeadline-Light.woff2') format('truetype');
}

body {
  margin: 0;
  font-family: 'AweleText', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}
.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}

.bg-biege {
  background-color: beige;
}

.bg-antiquewhite {
  background-color: antiquewhite;
}

.bg-aweleblue {
  background-color: #3e79f7;
}

.selected-tab {
  background-color: #3e79f7;
  color: white !important;
}

.w-3 {
  width: 33.33% !important;
}
.adjusted-center {
  margin-top: -200px !important;
}

.text-orange {
  color: rgb(247, 154, 14) !important;
}

.cursor-pointer {
  cursor: pointer;
}

.box {
  transition: box-shadow 0.3s;
  border-radius: 10px;
  border: 0px solid #ccc;
  background: #fff;
  float: left;
}
.box:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
}

/* .quote-bg-image{
  background-image: url('assets/img/ship_image.png');
  background-repeat: no-repeat;
} */

/* .quotes .ant-tabs-tab-active .quote-header p{
  color: white!important;
}

.quotes .ant-tabs-tab-active{
  background-color: rgb(247, 154, 14) !important;
} */

.bg-lcl {
  background-color: #ffffff !important;
}

.city-path {
  color: #000 !important;
}

.city-path-desc {
  color: #000 !important;
  font-size: 9px !important;
}

.city-path-connector {
  display: flex;
  align-items: center;
}

.dot-line-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.dot-line-horizontal {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #000;
  transform: translateY(-50%);
}

.dot-line-vertical {
  position: absolute;
  top: 0;
  right: 50%;
  width: 2px;
  height: 50%;
  background-color: #000;
  transform: translateX(50%);
}

.dot-line-container {
  position: relative;
  flex-grow: 1;
}

.dot-line {
  height: 1px;
  background: #000;
  background-image: radial-gradient(circle, #000 0.5px, transparent 0.5px);
  background-size: 5px 5px;
  width: 100%;
}

.dot {
  position: relative;
  top: 3px;
  width: 6px;
  height: 6px;
  background: blue;
  border-radius: 50%;
}

.dot-start {
  left: 0%; /* Position the starting dot on the left side */
}

.dot-end {
  right: -100%; /* Position the ending dot on the right side */
  top: -3px;
}

.dot-red {
  background: red;
}

.dot-green {
  background: green;
}

.duration {
  font-size: 10px;
}

.path-cost {
  font-size: 12px !important;
  color: #000 !important;
}

.total-price {
  height: 100%;
  width: 12%;
  padding: 10px !important;
}

.disable-path {
  opacity: 0.3;
}

.consolidator_name,
.no_of_shipments,
.no_of_reviews,
.rating_text {
  color: #000;
}
.no_of_shipments,
.no_of_reviews,
.rating_text {
  font-size: 12px;
}

.total_title {
  font-size: 10px;
}

.forex_text {
  font-size: 6px;
}

.total_amount_value {
  font-size: 17px;
}

.book_now_button {
  margin-top: 5px !important;
  width: 95px !important;
  height: 30px !important;
  line-height: 0 !important;
  font-size: 12px !important;
}

.book_now_button_mobile {
  margin-left: 5px !important;
  width: 60px !important;
  height: 18px !important;
  line-height: 0 !important;
  font-size: 9px !important;
}

.share_now_button {
  height: 30px !important;
  line-height: 0 !important;
  font-size: 12px !important;
  background-color: transparent !important;
  color: blue !important;
  border: none !important; /* Removes the default border */
  cursor: pointer; /* Add a pointer on hover */
}

.share-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.share-buttons {
  display: flex; /* Adds flexbox layout */
  justify-content: space-around; /* Centers the items horizontally with equal space */
  width: 100%; /* Takes up full width of the parent container */
  margin-top: 5px !important;
  height: 30px !important;
  line-height: 0 !important;
  font-size: 12px !important;
  background-color: transparent !important;
  color: blue !important;
  border: none !important;
}

.share-button {
  font-size: 32px;
  margin: 0 10px; /* Adds horizontal margin between icons */
}

.separator-line {
  margin-top: 20px;
  margin-bottom: 10; /* Adjust as needed */
  padding: 0; /* Adjust as needed */
}

.url-display {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute; /* Fixing the div at the bottom */
  bottom: 20px; /* Spacing from the bottom */
  left: 40px; /* Spacing from the left */
  right: 20px; /* Spacing from the right */
  font-size: 14px;
}

.url-input {
  border: none;
  outline: none;
  padding: 5px;
  font-size: 14px;
  flex-grow: 1;
  color: #007bff;
  background: transparent;
  word-break: break-all;
  text-align: left; /* Center the URL */
}

.copy-button {
  background: none;
  color: #007bff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.copy-button:hover {
  color: #0056b3;
}

.no-margin-bottom {
  margin-bottom: 5px !important;
  margin-top: 5px !important;
  position: relative;
}

.mt-47 {
  margin-top: -47px !important;
}

.mt-65 {
  margin-top: -65px !important;
}

.port_of_departure {
  font-size: 14px !important;
}

.hand-drawn-circle {
  border: 1px solid black;
  border-radius: 50%;
}

.floating-buttons {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 5px;
}

.button {
  padding: 10px 15px;
  background-color: #012140;
  color: white;
  text-align: center;
  font-size: 16px;
  transition: all 0.5s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50px;
  overflow: hidden;
  position: relative;
}

.checkbox {
  display: none;
}

.checkbox + span:before {
  content: '';
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 10px;
  border: 2px solid #fff;
  border-radius: 4px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 75%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.checkbox:checked + span:before {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="7" stroke-linecap="round" stroke-linejoin="round"><polyline points="20 6 9 17 4 12"/></svg>');
}

.checkbox:checked + span {
  color: #fff;
  font-weight: 700;
}

.button:hover {
  transform: scale(1.1);
}

.container_description {
  font-size: 10px !important;
  margin-left: 5px !important;
}

.filter-button i {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.filter-button:hover {
  background-color: #4caf50;
  color: white;
}

.ribbon {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #f1daa7;
  padding: 5px 10px;
  font-weight: bold;
  color: black;
  font-size: 10px;
}

.ribbon::before {
  content: '';
  position: absolute;
  right: 0;
  left: -10px;
  border-style: solid;
  border-width: 0 0 0 10px;
  border-color: transparent transparent transparent yellow;
}

.ribbon::after {
  content: '';
  position: absolute;
  bottom: 0;
  right: -10px;
  border-style: solid;
  border-width: 0 0 0 10px;
  border-color: yellow transparent transparent transparent;
}

.mandatory_clearing {
  font-size: 11px;
  color: red;
}

.count-circle {
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: red;
  margin-right: 8px;
}

.count {
  display: inline-block;
  line-height: 12px;
  font-size: 12px;
  color: white;
  text-align: center;
}

.floating-buttons .w-tooltip {
  z-index: 999999999999999999999999 !important;
}

.right-side-floating-prefill-cart {
  position: fixed;
  bottom: 14%;
  right: 50px;
  z-index: 9999;
  transform: 'translateY(-50%)';
}
.right-side-floating-share {
  position: fixed;
  bottom: 8%;
  right: 50px;
  z-index: 9999;
  transform: 'translateY(-50%)';
}

.clickable-div {
  cursor: pointer;
}

.vertical-line-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.circle-container {
  display: flex;
  align-items: center;
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #000; /* Color of the circles */
  margin-right: 10px; /* Space between circle and text */
}

.small-circle {
  width: 5px;
  height: 5px;
  margin: 5px 5px; /* Spacing between small circles */
  margin-right: 10%;
}

.large-circle {
  width: 20px;
  height: 20px;
}

.point-text {
  font-size: 14px; /* Adjust font size as needed */
}
